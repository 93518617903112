<!--数据分析课程详情-->
<template>
  <div>
    <template v-if="$route.name == 'dataAnalysisDetail2'">
      <div class="course-name">
        <div class="flex-center name">
          <span class="t1">{{ scheduleInfo.name }}</span>
        </div>
        <div class="time">
          {{ scheduleInfo.live_status | getLiveStatus }} |
          {{ scheduleInfo.startTime | formatTimeStamp('yyyy-MM-dd hh:mm') }} -
          {{ scheduleInfo.endTime | formatTimeStamp('yyyy-MM-dd hh:mm') }} |
          {{ scheduleInfo.teacher_name }}
        </div>
      </div>
      <!--概况-->
      <div class="bgf mt20">
        <div class="title pad20">直播概况</div>
        <div class="flex-center flex-list">
          <digitalDisplay
            text="累计学习人数"
            :number="surveyInfo.study_number"
            prompt="直播创建开始至今，学员进入直播间学习总人数（更新频率为5分钟)直播中进入直播间观看直播才算学习行为，直播前进入直播间不算"
          ></digitalDisplay>
          <digitalDisplay
            text="累计学习次数"
            :number="surveyInfo.study_times"
            prompt="直播创建开始至今，学员进入直播间学习总次数（更新频率为5分钟)直播中进入直播间播放直播视频才算学习行为，直播前进入直播间不算"
          ></digitalDisplay>
          <digitalDisplay
            text="人均学习时长"
            :number="surveyInfo.average_study_times | formatTimeLength"
            prompt="直播创建开始至今学员进入直播间学习的人均时长；计算公式：所有用户学习总时长/总学习人数（更新频率为1天)"
          ></digitalDisplay>
        </div>
        <!--观看人数-->
        <div class="title pad20 flex-center">
          直播间人数
          <helpIcon
            style="margin-left: 6px"
            content="在线人数：直播创建开始至今，进入直播间的人数峰值；仅进入页面算在线人数<br/>
                    学习人数：直播创建开始至今，学员进入直播间学习总人数，直播中进入直播间观看直播才算学习行为
                    仅保留直播开始时间后30天的数据"
          ></helpIcon>
        </div>
        <div>
          <onlineMember
            :id="$route.query.sp_id"
            :type="$route.query.type"
          ></onlineMember>
        </div>
        <!--观看详情-->
        <div class="title pad20">直播学习详情</div>
        <div class="pad20">
          <el-form
            style="text-align: right"
            :inline="true"
            @submit.native.prevent
          >
            <el-form-item>
              <div class="flex-center">
                <el-select v-model="post1.searchType" placeholder="请选择">
                  <el-option
                    v-for="(item, index) in ['昵称', '真实姓名']"
                    :key="item"
                    :label="item"
                    :value="index + 1"
                  ></el-option>
                </el-select>
                <el-input
                  class="searchInput"
                  v-model="post1.name"
                  :placeholder="
                    post1.searchType == 2 ? '输入真实姓名搜索' : '输入昵称搜索'
                  "
                ></el-input>
              </div>
            </el-form-item>
          </el-form>
          <pagination2 :option="post1" url="/Record/liveDetails">
            <template scope="{ tableData: { list } }">
              <el-table
                :data="list"
                :header-cell-style="{
                  background: 'rgba(245,245,245,1)',
                  color: '#333333',
                }"
              >
                <el-table-column label="昵称" prop="user_name">
                  <template slot-scope="scope">
                    <div class="flex-center invite-nick">
                      <img class="photo" :src="scope.row.user_photo" alt />
                      <div class="name">{{ scope.row.user_name }}</div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="真实姓名"
                  prop="student_remarks"
                ></el-table-column>
                <el-table-column label="首次进入时间" prop="c_time">
                  <template slot-scope="scope">
                    {{ scope.row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
                  </template>
                </el-table-column>
                <el-table-column label="总学习时长" prop="learning_duration">
                  <template slot-scope="scope">
                    {{ scope.row.learning_duration | formatTimeLength }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="总学习次数"
                  prop="learning_num"
                ></el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <router-link
                      :to="`dataAnalysisDetail/viewDetail/id/${scope.row.id}?type=2`"
                      class="toDetail"
                    >
                      详情
                    </router-link>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </pagination2>
        </div>
      </div>
      <!--在线人次地理位置分布-->
      <div class="flex-center mt20 function-views">
        <div class="map">
          <div class="title flex-center">
            在线人次地理位置分布
            <helpIcon
              style="margin-left: 6px"
              prompt="进入页面即算人次，同一用户在不同地区算多个人次"
            ></helpIcon>
          </div>
          <chinaMap :dataList="mapDistribution"></chinaMap>
        </div>
        <div class="personnelRatio">
          <div class="title flex-center">
            学习终端系统人次占比
            <helpIcon
              style="margin-left: 6px"
              prompt="同一用户使用不同终端学习算多个人次"
            ></helpIcon>
          </div>
          <pieChart
            :options="pieOption"
            :pieData="view_numbers"
            style="width: 100%; bottom: 20px; left: 0; top: 39px; height: auto"
          ></pieChart>
        </div>
      </div>
      <loading v-show="loading" leftPos="0"></loading>
    </template>
    <router-view />
  </div>
</template>
<script>
import bg from '@/mixin/background'
import helpIcon from '@/components/helpIcon'
import digitalDisplay from '@/components/dataAnalysis/digitalDisplay'
import onlineMember from '@/components/dataAnalysis/onlineMember'
import chinaMap from '@/components/dataAnalysis/chinaMap'
import pieChart from '@/components/dataAnalysis/pieChart'

export default {
  name: 'dataAnalysisDetail2',

  mixins: [bg],

  components: {
    chinaMap,
    helpIcon,
    pieChart,
    onlineMember,
    digitalDisplay,
  },

  data() {
    return {
      loading: true,
      // 邀请 和 打赏
      post1: {
        course_type: this.$route.query.course_type,
        course_id: this.$route.query.course_id,
        name: '',
        sp_id: this.$route.query.sp_id,
        type: this.$route.query.type,
        searchType: 1,
      },
      post2: {
        course_type: this.$route.query.course_type,
        course_id: this.$route.query.course_id,
        name: '',
        sp_id: this.$route.query.sp_id,
        type: this.$route.query.type,
      },

      liveDetails: {}, // 直播观看详情
      surveyInfo: {}, // 直播概况
      scheduleInfo: {}, // 课程信息
      view_numbers: [], // 观看终端系统次数占比
      mapDistribution: [], // 地理位置分布

      pieOption: {
        series: [
          {
            name: '访问来源',
          },
        ],
      },
    }
  },

  watch: {
    $route: {
      handler(to) {
        if (to.name == 'dataAnalysisDetail2') {
          this.mapData()
          this.getData()
        }
      },
      immediate: true,
    },
  },

  filters: {
    getLiveStatus(value) {
      let text
      switch (Number(value)) {
        case 2:
          text = '已结束'
          break
        case 3:
          text = '未开始'
          break
        case 4:
          text = '直播中'
          break
        default:
          text = '全部'
      }
      return text
    },
  },

  methods: {
    getData() {
      this.$http({
        url: '/Record/liveSurvey',
        data: {
          sp_id: this.$route.query.sp_id,
          type: this.$route.query.type,
        },
        callback: ({
          data: { liveDetails, surveyInfo, scheduleInfo, view_numbers },
        }) => {
          this.liveDetails = liveDetails
          this.surveyInfo = surveyInfo
          this.scheduleInfo = scheduleInfo
          this.view_numbers = view_numbers
          this.loading = false
        },
      })
    },

    // 获取地理位置数据
    mapData() {
      this.$http({
        url: '/Record/liveCity',
        data: {
          sp_id: this.$route.query.sp_id,
          type: this.$route.query.type,
        },
        callback: ({ data }) => {
          this.mapDistribution = data
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.title {
  font-size: 14px;
  font-weight: bold;
  color: rgba(51, 51, 51, 1);
  line-height: 19px;
}
.course-name {
  padding: 20px;
  background: #fff;
  .name {
    .t1 {
      font-size: 14px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
      line-height: 19px;
    }
    .t2 {
      width: 46px;
      height: 16px;
      font-size: 10px;
      margin-left: 4px;
      border-radius: 0px;
      line-height: 18px;
      text-align: center;
      color: rgba(27, 157, 151, 1);
      border: 1px solid rgba(27, 157, 151, 1);
    }
  }
  .time {
    font-size: 12px;
    margin-top: 12px;
    color: rgba(102, 102, 102, 1);
    line-height: 16px;
  }
}
.flex-center.flex-list {
  padding-right: 20px;
}
.map,
.personnelRatio {
  flex: 1;
  height: 354px;
  padding: 20px;
  position: relative;
  background: #fff;
  box-sizing: border-box;
}
.function-views {
  > div:nth-child(2n) {
    margin-left: 20px;
  }
}
.toDetail {
  font-size: 14px;
  color: $color;
  line-height: 19px;
  text-decoration: none;
}
.invite,
.reward {
  flex: 1;
  max-width: 100%;
  padding: 20px;
  background: #fff;
  max-width: 100%;
  box-sizing: border-box;
  .table-menu {
    margin: 20px 0;
    .item {
      flex: 1;
      .title {
        font-size: 13px;
        line-height: 17px;
        color: rgba(51, 51, 51, 1);
      }
      .number {
        font-size: 24px;
        font-weight: bold;
        line-height: 31px;
        margin-top: 6px;
        color: rgba(51, 51, 51, 1);
      }
    }
  }
}
.map {
  width: 48%;
  flex: none;
}
.invite-nick {
  .photo {
    width: 20px;
    height: 20px;
    border-radius: 10px;
  }
  .name {
    font-size: 14px;
    margin-left: 10px;
    color: rgba(51, 51, 51, 1);
    line-height: 19px;
  }
}
</style>
